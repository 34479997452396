import {
	chakra,
	useMultiStyleConfig,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { navigationCss } from "src/styles/swiper";
import { A11y, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide, SwiperSlideProps } from "swiper/react";

export type HeroSliderProps = {
	children: ReactNode;
};

const ChakraSwiper = chakra(Swiper);

const ChakraSwiperSlide = chakra(SwiperSlide);

export const HeroSlider: React.FC<HeroSliderProps> = ({ children }) => {
	const styles = useMultiStyleConfig("HeroSlider");
	const showNavigation = useBreakpointValue(
		{
			base: true,
			xl: true,
		},
		{ fallback: "base" },
	);

	return (
		<chakra.div __css={styles.wrapper} css={navigationCss}>
			<ChakraSwiper
				slidesPerView={1}
				navigation={showNavigation ?? false}
				grabCursor={true}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, A11y, Navigation]}
			>
				{children}
			</ChakraSwiper>
		</chakra.div>
	);
};

export const HeroSliderItem: React.FC<SwiperSlideProps> = ({
	children,
	...rest
}) => {
	return (
		<ChakraSwiperSlide height="full" {...rest}>
			{children}
		</ChakraSwiperSlide>
	);
};

// 🔬 TBD: e2e
