// Copied and then heavily edited from:
// https://github.com/nolimits4web/swiper/edit/master/src/modules/navigation/navigation.scss

import { css } from "@emotion/react";
import { getInlineSvg } from "./getInlineSvg";

const arrow = (color: string) =>
	getInlineSvg(
		`<svg fill="none" viewBox="0 0 12 20"><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m2 17.942 7.971-7.971L2 2"/></svg>`,
	);

export const navigationCss = css`
	:root {
		--swiper-navigation-size: 2.75rem;
	}

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		top: 50%;
		width: var(--swiper-navigation-size);
		height: var(--swiper-navigation-size);
		margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
		z-index: 10;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--swiper-navigation-color, var(--swiper-theme-color));
		background-color: white;
		border-radius: 999px;
		transition:
			color 300ms,
			background 200ms,
			box-shadow 400ms;
		box-shadow: 0.25rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);

		&.swiper-button-disabled {
			background: rgba(255, 255, 255, 0.5);
			cursor: auto;
			pointer-events: none;
			&::after {
				background-image: ${arrow("white")};
			}
		}

		&.swiper-button-hidden {
			opacity: 0;
			cursor: auto;
			pointer-events: none;
		}

		.swiper-navigation-disabled & {
			display: none !important;
		}

		&::after {
			content: "";
			display: block;
			width: ${12 / 16}rem;
			height: 1rem;
			background-image: ${arrow("#51C519")};
			background-repeat: no-repeat;
			background-position: center center;
		}

		&:hover {
			background: var(--chakra-colors-brand-highlight);
			box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
			&::after {
				background-image: ${arrow("white")};
			}
		}
	}

	.swiper-button-prev,
	.swiper-rtl .swiper-button-next {
		left: 2rem;
		right: auto;
		&::after {
			rotate: 0.5turn;
		}
	}

	.swiper-button-next,
	.swiper-rtl .swiper-button-prev {
		right: 2rem;
		left: auto;
	}

	.swiper-button-lock {
		display: none;
	}
`;

// 🔬 TBD: e2e
